import * as mutation from "@/graphql/mutations";
import { getSettings, listPublicAPIBearerToken, listDispositions, getCustomCRM, getCustomsLinks } from "@/graphql/queries";
import * as subscriptions from "@/graphql/subscriptions";

import { API, graphqlOperation } from "aws-amplify";

import { Spin } from "view-design";
import _ from "lodash";
import { deleteRemnantsOfProp } from "@/common/support";
import { i18n } from "@/plugins/language";
// eslint-disable-next-line
import { fetcherAmplify } from "@/libs/fetcher";
import { CUSTOM_LINKS } from "@connectpath/common-frontend";

if (!window.localStorage.getItem("language")) {
  window.localStorage.setItem("language", i18n.locale === "en-US" ? "en" : "es");
}

export default {
  namespaced: true,
  state: {
    isUpdatingConfig: false,
    configuration: {
      ContactLens: {
        isEnabled: null,
        isAllowed: null,
      },
      Poly: {
        isEnabled: false,
        isAllowed: null,
      },
      Theme: null,
      MultiLevelSeries: "INACTIVE",
      PresentedNotAnswered: {
        Label: null,
        Status: null,
        Duration: null,
      },
      CallDispositions: {
        isEnabled: false,
        allowCustomInput: false,
      },
      CustomCRM: {
        isAllowed: false,
        isEnabled: false,
        id: "",
      },
      OutboundMessages: {
        isEnabled: false,
        ContactFlow: "",
      },
      ThirdPartyIntegrations: null,
      DefaultOutboundChannels: {
        EmailChannelId: "",
        SmsChannelId: "",
      },
      IdleActive: false,
      IdleStatus: "",
      IdleTime: 1,
    },
    callDispositions: [],
    darkMode: false,
    transitionName: "",
    availableCallDispositions: [],
    language: window.localStorage.getItem("language"),
    availableLanguages: [
      { iso: "en", name: "English" },
      { iso: "es", name: "Spanish" },
      { iso: "pt", name: "Portuguese" },
      { iso: "fr", name: "French" },
      { iso: "af", name: "Afrikaans" },
    ],
    ccpUrlDeepLink: null,
    customLinks: [],
  },
  getters: {
    isDarkMode(state) {
      return state.darkMode;
    },
    isCallDispositionEnabled(state) {
      return state.configuration.CallDispositions.isEnabled;
    },
    isAvailableCallDispositions(state) {
      return !!state.availableCallDispositions.length;
    },
    availableCallDispositions(state) {
      return state.availableCallDispositions;
    },
    isMultilevelDispositionEnabled(state) {
      return state.configuration.MultiLevelSeries === "ACTIVE";
    },
    isCustomInputDispositionEnabled(state) {
      return state.configuration.CallDispositions.allowCustomInput;
    },
    currentUserLanguage(state) {
      return state.language || window.localStorage.getItem("language");
    },
    isUpdatingConfig(state) {
      return state.isUpdatingConfig;
    },
    configurations(state) {
      return state.configuration;
    },
    callDispositionAllowCustomInput(state) {
      return state.configuration.CallDispositions.allowCustomInput;
    },
    isOutboundMessagesEnabled(state) {
      return state.configuration.OutboundMessages.isEnabled;
    },
    getCallDispositions(state) {
      return state.callDispositions;
    },
    isContactLensEnabled(state) {
      return !!state.configuration.ContactLens;
    },
    getCcpUrlDeepLink(state) {
      return state.ccpUrlDeepLink;
    },
    getDefaultOutboundChannels(state) {
      return state.configuration?.DefaultOutboundChannels || {};
    },
    getDefaultOutboundEmailId(state) {
      return state.configuration?.DefaultOutboundChannels?.EmailChannelId;
    },
    getDefaultOutboundSmsId(state) {
      return state.configuration?.DefaultOutboundChannels?.SmsChannelId;
    },
    isReportingAllowed(state) {
      if (state.configuration?.Reporting?.isAllowed) {
        return state.configuration.Reporting.isAllowed;
      }
      return false;
    },
    getIdleActive(state) {
      return state.configuration.IdleActive;
    },
    getIdleStatus(state) {
      return state.configuration.IdleStatus;
    },
    getIdleTime(state) {
      return state.configuration.IdleTime;
    },
    getCustomLinks(state) {
      return state.customLinks;
    },
  },
  mutations: {
    updateOutboundMessages(state, v) {
      state.configuration.OutboundMessages.isEnabled = v;
    },
    removedDisposition(state, v) {
      for (let x = 0; x < state.callDispositions.length; x++) {
        if (state.callDispositions.id == v.id) {
          state.callDispositions.splice(x, 1);
          return false;
        }
      }
    },
    setSettings(state, v) {
      v.MultiLevelSeries = v.MultiLevelSeries ?? "INACTIVE";
      state.configuration = {
        ...v,
      };

      const timeout = setTimeout(() => {
        Spin.hide();
        clearTimeout(timeout);
      }, 1000);
    },
    setCallDispositions(state, payload) {
      state.callDispositions = payload;
      const timeout = setTimeout(() => {
        Spin.hide();
        clearTimeout(timeout);
      }, 1000);
    },
    setDarkMode(state, value) {
      state.darkMode = value;
    },
    setTransitionName(state, value) {
      state.transitionName = value;
    },
    initSettings(state) {
      state.isUpdatingConfig = false;
      state.configuration = {
        Theme: null,
        ContactLens: {
          isEnabled: null,
          isAllowed: null,
        },
        PresentedNotAnswered: {
          Label: null,
          Status: null,
          Duration: null,
        },
        CallDispositions: {
          isEnabled: false,
          allowCustomInput: false,
        },
        CustomCRM: {
          isAllowed: false,
          isEnabled: false,
        },
        OutboundMessages: {
          isEnabled: false,
          ContactFlow: "",
        },
        ThirdPartyIntegrations: null,
        DefaultOutboundChannels: {
          EmailChannelId: "",
          SmsChannelId: "",
        },
        IdleActive: false,
        IdleStatus: "",
        IdleTime: 1,
      };
      state.callDispositions = [];
      state.darkMode = false;
      state.transitionName = "";
      state.language = window.localStorage.getItem("language");
    },
    updateState(state, value) {
      state.isUpdatingConfig = value.isUpdatingConfig;
      state.configuration = value.configuration;
      state.callDispositions = value.callDispositions;
      state.darkMode = value.darkMode;
      state.transitionName = value.transitionName;
      state.language = value.language;
    },
    updateCustomCRM(state, value) {
      state.configuration.CustomCRM = value;
    },
    setAvailableCallDispositions(state, value) {
      state.availableCallDispositions = value;
    },
    setLanguage(state, value) {
      state.language = value;
    },
    updateCcpUrlDeepLink(state, payload) {
      state.ccpUrlDeepLink = payload;
    },
    setDefaultOutboundChannels(state, value) {
      state.configuration.DefaultOutboundChannels = value;
    },
    setIdleActive(state, IdleActive) {
      state.configuration.IdleActive = IdleActive;
    },
    setIdleStatus(state, IdleStatus) {
      state.configuration.IdleStatus = IdleStatus;
    },
    setIdleTime(state, IdleTime) {
      state.configuration.IdleTime = IdleTime;
    },
    updateCustomLinks(state, payload) {
      state.customLinks = payload;
    },
  },
  actions: {
    setAvailableCallDispositionsByQueue({ commit, getters }, { queueArn }) {
      const callDispositions = getters.getCallDispositions;
      const availableCallDispositions = callDispositions.filter((disposition) => {
        const { Queues } = disposition;
        const queues = Queues.map((queue) => queue.Arn);
        return queues.includes(queueArn);
      });
      commit("setAvailableCallDispositions", availableCallDispositions);
    },
    updateAccountSub(context, payload) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onUpdateAccount, {
          InstanceId: payload,
        })
      );
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateAccount;
          if (newData) {
            context.commit("currentUser/setInstanceAccount", newData, {
              root: 1,
            });
          }
          return Promise.resolve(newData);
        },
      };

      context.commit("addSubscriptionToList", { id: "updateAccountSub", observable, handler }, { root: true });
    },
    settingsSubs(context, payload) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onUpdateSettings, {
          InstanceId: payload,
        })
      );
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateSettings;
          if (newData) {
            context.commit("setSettings", newData);
          }
          return Promise.resolve(newData);
        },
      };

      context.commit("addSubscriptionToList", { id: "settingsSubs", observable, handler }, { root: true });
    },
    dispositionSubs(context, payload) {
      const observable = API.graphql(
        graphqlOperation(subscriptions.onNewDisposition, {
          InstanceId: payload,
        })
      );
      const handler = {
        next: (eventData) => {
          const newDisposition = eventData.value.data.onNewDisposition;
          if (newDisposition) {
            Spin.show();
            let currentDispositions = context.state.callDispositions;
            if (!currentDispositions.find((x) => x.id === newDisposition.id)) {
              currentDispositions.push({
                Name: newDisposition.Name,
                Queues: [...newDisposition.Queues],
                id: newDisposition.id,
              });
              context.commit("setCallDispositions", currentDispositions);
            }
          }
          Spin.hide();
          return Promise.resolve(eventData);
        },
      };

      context.commit("addSubscriptionToList", { id: "dispositionSubs", observable, handler }, { root: true });
    },
    getConfigs(context, payload) {
      return API.graphql(
        graphqlOperation(getSettings, {
          InstanceId: payload,
        })
      )
        .then((res) => {
          context.commit("setSettings", res.data.getSettings);
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    updateConfigs(context, payload) {
      context.state.isUpdatingConfig = true;
      deleteRemnantsOfProp(payload, ["__typename"]);
      return API.graphql(graphqlOperation(mutation.updateSettings, payload))
        .then((res) => {
          context.state.isUpdatingConfig = false;
          context.commit("setSettings", res.data.updateSettings);
          return Promise.resolve(res);
        })
        .catch((err) => {
          context.state.isUpdatingConfig = false;
          return Promise.reject(err);
        });
    },
    addNewCallDispositions(context, payload) {
      context.state.isUpdatingConfig = true;
      return API.graphql(graphqlOperation(mutation.createDisposition, payload))
        .then((res) => {
          context.state.isUpdatingConfig = false;
          return Promise.resolve(res);
        })
        .catch((err) => {
          context.state.isUpdatingConfig = false;
          return Promise.reject(err);
        });
    },
    updateSelectedCallDisposition(context, payload) {
      context.state.isUpdatingConfig = true;
      return API.graphql(graphqlOperation(mutation.updateDisposition, payload))
        .then((res) => {
          context.state.isUpdatingConfig = false;
          return Promise.resolve(res);
        })
        .catch((err) => {
          context.state.isUpdatingConfig = false;
          return Promise.reject(err);
        });
    },
    createNewSeries(context, payload) {
      context.state.isUpdatingConfig = true;
      return API.graphql(graphqlOperation(mutation.createLevelList, payload))
        .then((res) => {
          context.state.isUpdatingConfig = false;
          return Promise.resolve(res);
        })
        .catch((err) => {
          context.state.isUpdatingConfig = false;
          return Promise.reject(err);
        });
    },
    createNewScript(context, payload) {
      context.state.isUpdatingConfig = true;
      return API.graphql(graphqlOperation(mutation.createScript, payload))
        .then((res) => {
          context.state.isUpdatingConfig = false;
          return Promise.resolve(res);
        })
        .catch((err) => {
          context.state.isUpdatingConfig = false;
          return Promise.reject(err);
        });
    },
    createNewToken(context, payload) {
      context.state.isUpdatingConfig = true;
      context.state.isUpdatingConfig = false;
      try {
        const response = API.graphql(graphqlOperation(mutation.createPublicAPIBearerToken, payload));
        context.state.isUpdatingConfig = false;
        return response;
      } catch (e) {
        context.state.isUpdatingConfig = false;
        return e;
      }
    },
    deleteToken(context, payload) {
      return API.graphql(
        graphqlOperation(mutation.deletePublicAPIBearerToken, {
          input: {
            id: payload.id,
            InstanceId: payload.InstanceId,
            Username: payload.Username,
          },
        })
      );
    },
    getTokenData(context, payload) {
      return API.graphql(
        graphqlOperation(listPublicAPIBearerToken, {
          InstanceId: payload.InstanceId,
          Username: payload.Username,
        })
      );
    },
    updateSeriesData(context, payload) {
      context.state.isUpdatingConfig = true;
      return API.graphql(graphqlOperation(mutation.updateLevelList, payload))
        .then((res) => {
          context.state.isUpdatingConfig = false;
          return Promise.resolve(res);
        })
        .catch((err) => {
          context.state.isUpdatingConfig = false;
          return Promise.reject(err);
        });
    },
    updateScriptData(context, payload) {
      context.state.isUpdatingConfig = true;
      return API.graphql(graphqlOperation(mutation.updateScript, payload))
        .then((res) => {
          context.state.isUpdatingConfig = false;
          return Promise.resolve(res);
        })
        .catch((err) => {
          context.state.isUpdatingConfig = false;
          return Promise.reject(err);
        });
    },
    fetchCallDipositions({ commit }, payload) {
      return API.graphql(graphqlOperation(listDispositions, payload))
        .then((res) => {
          commit("setCallDispositions", _.get(res, "data.listDispositions.items", []));
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    deleteCallDisposition(context, payload) {
      return API.graphql(graphqlOperation(mutation.deleteDisposition, payload)).then((res) => {
        return Promise.resolve(res);
      });
    },
    createCustomCRM({ commit }, payload) {
      return API.graphql(graphqlOperation(mutation.createCustomCRM, payload))
        .then((res) => {
          commit("updateCustomCRM", {
            isEnabled: true,
            isAllowed: true,
            id: res.data.createCustomCRM.id,
          });
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getCustomCRM(_, payload) {
      return API.graphql(graphqlOperation(getCustomCRM, payload))
        .then((res) => {
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    updateCustomCRM(_, payload) {
      return API.graphql(graphqlOperation(mutation.updateCustomCRM, payload))
        .then((res) => {
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    deleteCustomCRM({ commit }, payload) {
      return API.graphql(graphqlOperation(mutation.deleteCustomCRM, payload))
        .then((res) => {
          commit("updateCustomCRM", {
            isEnabled: false,
            isAllowed: true,
            id: null,
          });
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    addCcpUrlDeepLink(context, value) {
      context.commit("updateCcpUrlDeepLink", value);
    },

    async updateDefaultOutboundChannels({ dispatch, commit }, { DefaultOutboundChannels, InstanceId }) {
      try {
        await dispatch("updateConfigs", {
          input: {
            InstanceId,
            DefaultOutboundChannels,
          },
        });
        commit("setDefaultOutboundChannels", DefaultOutboundChannels);
      } catch (error) {
        console.error(error);
      }
    },
    setInactivitySettings({ commit }, { IdleActive, IdleStatus, IdleTime }) {
      commit("setIdleActive", IdleActive);
      commit("setIdleStatus", IdleStatus);
      commit("setIdleTime", IdleTime);
    },

    async saveInactivitySettings({ dispatch }, { InstanceId, IdleActive, IdleStatus, IdleTime }) {
      try {
        await dispatch("updateConfigs", {
          input: {
            InstanceId,
            IdleActive,
            IdleStatus,
            IdleTime,
          },
        });
        dispatch("setInactivitySettings", { IdleActive, IdleStatus, IdleTime });
        dispatch("ui/messageEndpointSuccess", { message: `${i18n.t("settings.inactivitySettingsUpdated")}` }, { root: true });
      } catch (error) {
        dispatch("ui/messageEndpointError", { message: `${i18n.t("settings.inactivitySettingsError")}` }, { root: true });
        console.error(`saveInactivitySettings -> `, error);
      }
    },

    async createCustomLink(context, { name, url, type, browserTab, scope, awsAgentId }) {
      try {
        return await fetcherAmplify().graphql({
          query: mutation.createCustomUrl,
          variables: {
            input: {
              Name: name,
              BaseUrl: url,
              Type: type,
              BrowserTab: browserTab,
              Scope: scope,
              AWSAgentID: awsAgentId,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async updateCustomLinks(context, { id, name, url, type, browserTab, scope, awsAgentId }) {
      try {
        return await fetcherAmplify().graphql({
          query: mutation.updateCustomUrl,
          variables: {
            input: {
              Id: id,
              Name: name,
              BaseUrl: url,
              Type: type,
              BrowserTab: browserTab,
              Scope: scope,
              AWSAgentID: awsAgentId,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async fetchCustomLinks(context, { awsAgentId }) {
      try {
        const response = await fetcherAmplify().graphql({
          query: getCustomsLinks,
        });
        const customLinksFilteredByScope = response?.listCustomUrl.filter(
          (link) =>
            !link.Scope ||
            link.Scope === CUSTOM_LINKS.GLOBAL_SCOPE ||
            (CUSTOM_LINKS.PRIVATE_SCOPE && link.AWSAgentID === awsAgentId)
        );
        return customLinksFilteredByScope.map((item) => {
          return {
            id: item.Id,
            name: item.Name,
            url: item.BaseUrl,
            type: item.Type,
            browserTab: item.BrowserTab,
            scope: item.Scope,
            awsAgentId: item.AWSAgentID,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },

    async deleteCustomLink(context, { id }) {
      try {
        return await fetcherAmplify().graphql({
          query: mutation.deleteCustomUrl,
          variables: {
            input: {
              Id: id,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    appendCustomLinks({ commit }, payload) {
      commit("updateCustomLinks", payload);
    },
  },
};
