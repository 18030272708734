export default {
  namespaced: true,
  state: {
    timeSeriesType: "",
    agentStatusFilter: "",
    startDate: new Date(),
    endDate: new Date(),
    selectedFilter: {
      label: "Today",
      value: "today",
    },
    isPresent: true,
    agentPerformanceTable: [],
    queuePerformanceTable: [],
    agentPerformanceOfflineFilter: false,
  },
  mutations: {
    setTimeSeriesType(state, value) {
      state.timeSeriesType = value;
    },
    setAgentStatusFilter(state, value) {
      state.agentStatusFilter = value;
    },
    setAgentPerformanceOfflineFilter(state, value) {
      state.agentPerformanceOfflineFilter = value;
    },
    setStartDate(state, value) {
      state.startDate = value;
    },
    setEndDate(state, value) {
      state.endDate = value;
    },
    setSelectedFilter(state, value) {
      state.selectedFilter = value;
    },
    setIsPresent(state, value) {
      state.isPresent = value;
    },
    setAgentPerformanceTable(state, value) {
      state.agentPerformanceTable = value;
    },
    setQueuePerformanceTable(state, value) {
      state.queuePerformanceTable = value;
    },
  },
  getters: {
    dateRange(state) {
      const startDate = new Date(state.startDate);
      const endDate = new Date(state.endDate);

      return [startDate.toISOString(), endDate.toISOString()];
    },
    getAgentPerformanceOfflineFilter(state) {
      return state.agentPerformanceOfflineFilter;
    },
    getTimeSeriesType(state) {
      return state.timeSeriesType;
    },
  },
  actions: {
    updateAgentPerformanceOfflineFilter({ commit }, payload) {
      commit("setAgentPerformanceOfflineFilter", payload);
    },
    updateTimeSeriesType({ commit }, payload) {
      commit("setTimeSeriesType", payload);
    },
  },
};
